<template>
  <b-card no-body>
    <b-card-title>Leaders</b-card-title>
    <!-- Max Display -->
    <b-form-row>
      <b-col sm="2">
        <input-stat
          component="form-validation-field"
          vid="max_display"
          class="text-left"
          name="leaders_to_show"
          label="Max Leaders To Show"
          rules="between:1,10"
          :value="maxDisplay"
          @input="$emit('update:max-display', parseInt($event))"
        />
      </b-col>
    </b-form-row>

    <!-- Minimums -->
    <b-form-row>
      <b-col
        v-for="statistic in statistics"
        :key="statistic.id"
        sm="auto"
      >
        <input-stat
          component="form-validation-field"
          class="text-left"
          :name="`leaders-${statistic.abbreviation}`"
          :label="'Min ' + statistic.name"
          :value="minimums[statistic.id] || 0"
          @input="$emit('update:minimums', {
            ...minimums,
            [statistic.id]: parseInt($event)
          })"
        />
      </b-col>
    </b-form-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCol, BFormRow,
} from 'bootstrap-vue'
import InputStat from '@/components/forms/inputs/InputStat.vue'

export default {
  components: {
    InputStat,
    BCard,
    BCardTitle,
    BCol,
    BFormRow,
  },
  props: {
    maxDisplay: {
      required: true,
      type: Number,
    },
    minimums: {
      required: true,
      type: Object,
    },
    statistics: {
      required: true,
      type: Array,
    },
  },
  created() {
    Object.keys(this.minimums).forEach(value => {
      if (!this.statistics.find(statistic => statistic.id === parseInt(value))) {
        delete this.minimums[value]
      }
    })
  },
}
</script>
<style scoped>

</style>
