<template>
  <b-card title="Settings">
    <validation-form
      ref="validationForm"
      :submit-buttons="{
        loading: form.isLoading,
        cancel: false,
      }"
      @validated="submit"
      @cancel="$router.push({ name: 'home'})"
    >
      <leaders-settings
        v-if="form.display.website.leaders"
        class="mt-3"
        :statistics="glossary.statistics.filter(statistic => statistic.leaderboard_minimum !== null && statistic.leaderboard_minimum)"
        :max-display.sync="form.display.website.leaders.max_display"
        :minimums.sync="form.display.website.leaders.minimums"
      />
    </validation-form>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import LeadersSettings from '@/views/events/settings/EventSettingsLeaders.vue'

import axios from '@/libs/axios'
import Form from '@/forms/Form'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventSettings',
  components: {
    BCard,
    ValidationForm,
    LeadersSettings,
  },
  data() {
    return {
      form: new Form({
        display: {
          website: {
            leaders: null,
          },
        },
      }),
      glossary: null,
    }
  },
  methods: {
    submit() {
      this.form.put(`/events/${this.$route.params.event}/settings`)
    },
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await axios.get(`events/${to.params.event}/settings`)

      next(vm => {
        Object.assign(vm.form, data.data)
        vm.glossary = data.glossary
      })
    } catch (e) {
      flashError(e)
      next()
    }
  },
}
</script>

<style scoped>

</style>
